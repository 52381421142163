import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

const Form = () => {
  const [visible, setVisible] = useState(false);
  const [err, setErr] = useState(false);
  useEffect(() => {
    if (visible) {
      const timer = setTimeout(() => {
        setVisible(false);
      }, 8000);
      return () => clearTimeout(timer);
    }
  }, [visible]);

  const {
    register,
    handleSubmit,
    //watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    fetch(
      "https://prod-11.eastasia.logic.azure.com:443/workflows/814e84acb2ca4542ace457638cd45daf/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=1OWphPg6t2Bx3MSosO_KSNohd27P3CRnvR2G3aKrJfQ",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setVisible(true);
        console.log(data);
      })
      .catch((error) => {
        setErr(true);
        console.log(error);
      });
  };

  return (
    <form
      className="flex flex-col gap-2 md:gap-5"
      onSubmit={handleSubmit(onSubmit)}
    >
      <label className="flex flex-col md:flex-row items-center justify-center">
        <span className="w-full md:w-1/5 mb-1 md:mb-0">会社名</span>
        <input
          className={`
          ${errors.company ? "bg-cyan-200 " : ""}
            w-full md:w-3/5 focus:outline-none focus:ring-2 focus:ring-cyan-300 focus:border-transparent`}
          type="text"
          placeholder="会社名"
          {...register("company", { required: true, message: "Required" })}
        />
      </label>
      <label className="flex flex-col md:flex-row items-center justify-center">
        <span className="w-full md:w-1/5 mb-1 md:mb-0">お名前</span>
        <input
          className={`
            ${errors.name ? "bg-cyan-200 " : ""}
              w-full md:w-3/5 focus:outline-none focus:ring-2 focus:ring-cyan-300 focus:border-transparent`}
          type="text"
          placeholder="お名前"
          {...register("name", { required: true })}
        />
      </label>
      <label className="flex flex-col md:flex-row items-center justify-center">
        <span className="w-full md:w-1/5 mb-1 md:mb-0">メール</span>
        <input
          className={`
          ${errors.email ? "bg-cyan-200 " : ""}
            w-full md:w-3/5 focus:outline-none focus:ring-2 focus:ring-cyan-300 focus:border-transparent`}
          type="text"
          placeholder="my@email.com"
          {...register("email", { required: true })}
        />
      </label>
      <label className="flex flex-col md:flex-row items-center justify-center">
        <span className="w-full md:w-1/5 mb-1 md:mb-0">お電話</span>
        <input
          className="w-full md:w-3/5 focus:outline-none focus:ring-2 focus:ring-cyan-300 focus:border-transparent"
          type="text"
          placeholder="123-456-789"
          {...register("tel")}
        />
      </label>
      <label className="flex flex-col md:flex-row items-center justify-center">
        <span className="w-full md:w-1/5 mb-1 md:mb-0">内容</span>
        <textarea
          className={`
          ${errors.message ? "bg-cyan-200 " : ""}
            w-full md:w-3/5 focus:outline-none focus:ring-2 focus:ring-cyan-300 focus:border-transparent`}
          rows="5"
          placeholder="例) 中国での組織再編について相談したい"
          {...register("message", { required: true })}
        />
      </label>
      {errors.company || errors.name || errors.email || errors.message ? (
        <span className="font-bold text-cyan-600 text-center">
          恐れ入りますが入力をお願いいたします。
        </span>
      ) : null}
      <div className="flex justify-center mt-5">
        <button
          className="w-full md:w-1/6 bg-cyan-800 hover:bg-cyan-700 text-white rounded-sm px-4 py-2 font-bold disabled:opacity-70"
          type="submit"
          disabled={visible}
        >
          送信
        </button>
      </div>
      {visible ? (
        <p className="text-center border-2 border-pine-500 mt-3 py-2 text-pine-600">
          お問い合わせありがとうございました。
        </p>
      ) : null}
      {err ? (
        <p className="text-center border-2 border-red-500 mt-3 py-2 text-red-600">
          フォームの調子が悪いため、恐れ入りますがメールまたはお電話にてお問い合わせください‥。
        </p>
      ) : null}
    </form>
  );
};

export default Form;
