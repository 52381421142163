import React from "react";
import { StaticImage } from "gatsby-plugin-image";

export const companies = [
  {
    name: "Mizuno Consultancy Holdings Limited．(水野諮詢集團有限公司)",
    region: "香港",
    address:
      "Unit B, 25/F., No.8 Hart Avenue, Tsim Sha Tsui, Kowloon, Hong Kong",
    tel: "TEL (852) 2522-0078",
    fax: "FAX (852) 2522-1173",
    comment: [],
    image: (
      <StaticImage
        src="../../images/about/mch_hk.jpg"
        className="rounded-sm"
        alt="Mizuno Consultancy Holdings Limited．"
        width={200}
        height={300}
      />
    ),
    type: "Consulting",
  },
  {
    name: "Mizuno Consultancy Holdings Ltd．日本代表事務所",
    region: "日本",
    address:
      "〒220-8120 神奈川県横浜市西区みなとみらい2-2-1 横浜ランドマークタワー20階",
    tel: "TEL (81) 45-277-3764",
    fax: "FAX (81) 45-277-3801",
    comment: [],
    image: (
      <StaticImage
        src="../../images/about/mch_yh.jpg"
        className="rounded-sm"
        alt="Mizuno Consultancy Holdings Ltd．日本代表事務所"
        width={200}
        height={300}
      />
    ),
    type: "Consulting",
  },
  {
    name: "水野商務諮詢(上海)有限公司",
    region: "上海",
    address: "〒200336 上海市長寧区仙霞路322号 鑫達大厦806室",
    tel: "TEL (86) 21-6167-1310",
    fax: "FAX (86) 21-6167-1326",
    comment: [],
    image: (
      <StaticImage
        src="../../images/about/mch_sh.jpg"
        className="rounded-sm"
        alt="水野商務諮詢(上海)有限公司"
        width={200}
        height={300}
      />
    ),
    type: "Consulting",
  },
  {
    name: "水野商務諮詢(広州)有限公司",
    region: "広州",
    address: "〒510620 広州市天河北路233号 中信広場38楼 3806D室",
    tel: "TEL (86) 20-3877-1275/3877-2350",
    fax: "FAX (86) 20-3877-1275",
    comment: [],
    image: (
      <StaticImage
        src="../../images/about/mch_gz.jpg"
        className="rounded-sm"
        alt="水野商務諮詢(広州)有限公司"
        width={200}
        height={300}
      />
    ),
    type: "Consulting",
  },
  {
    name: "MIZUNO CONSULTING VIETNAM COMPANY LIMITED.",
    region: "ベトナム",
    address:
      "31F, Saigon Trade Center, 37 Ton Duc Thang Street, Ben Nghe Ward, District 01, Ho Chi Minh City, Vietnam",
    tel: "TEL/FAX (84) 28-3910-1822",
    comment: [],
    image: (
      <StaticImage
        src="../../images/about/mch_vn.jpg"
        className="rounded-sm"
        alt="MIZUNO CONSULTING VIETNAM COMPANY LIMITED."
        width={200}
        height={300}
      />
    ),
    type: "Consulting",
  },
  {
    name: "深圳市水野企業管理咨詢有限公司",
    region: "深圳",
    address: "〒518001 深圳市南山区桂廟路口新緑島大厦3楼 3A25",
    tel: "TEL (86) 755-2605-1044",
    comment: [
      "経営範囲：記帳代行、企業登記代行、企業管理コンサルティング、投資コンサルティング、ビジネス情報コンサルティング",
    ],
    image: (
      <StaticImage
        src="../../images/about/mch_sz.jpg"
        className="rounded-sm"
        alt="深圳市水野企業管理咨詢有限公司"
        width={200}
        height={300}
      />
    ),
    type: "Accounting",
  },
  {
    name: "水野財務諮詢(上海)有限公司",
    region: "上海",
    address: "〒200336 上海市長寧区仙霞路322号 鑫達大厦806室",
    tel: "TEL (86) 21-6167-1311",
    fax: "FAX (86) 21-6167-1326",
    comment: [
      "経営範囲：記帳代行、企業登記代理、投資管理、企業管理、投資コンサルティング、ビジネスコンサルティング、実業投資",
    ],
    image: (
      <StaticImage
        src="../../images/about/mch_sh.jpg"
        className="rounded-sm"
        alt="水野財務諮詢(上海)有限公司"
        width={200}
        height={300}
      />
    ),
    type: "Accounting",
  },
  {
    name: "株式会社チェイス・ネクスト",
    region: "日本",
    address: "〒231-0062 神奈川県横浜市中区桜木町1丁目101番地 1クロスゲート7階",
    tel: "TEL/FAX 045-315-4946",
    comment: [
      "主業務：ビジネス情報ポータルサイトの運営、出版事業、セミナー・研修事業、デジタルコンテンツ（動画、レポートなど）の販売",
    ],
    image: "",
    type: "Publishing",
    website: "https://chasechina.jp",
  },
];

export const types = [
  {
    type: "Consulting",
    class: "bg-cyan-800",
    name: "コンサルティング",
  },
  {
    type: "Accounting",
    class: "bg-teal-800",
    name: "記帳代行・税務申告代理",
  },
  {
    type: "Publishing",
    class: "bg-cyan-600",
    name: "中国・アジアのビジネスコンテンツ制作",
  },
];
