import React from "react";
import Layout from "../components/layout";
import Form from "../components/form";
import { companies } from "../components/about/company";
import H1 from "../components/h1";
import Seo from "../components/seo";

const ContactPage = () => {
  return (
    <Layout>
      <Seo title="お問い合わせ" />
      <H1>お問い合わせ</H1>
      <div className="container mx-auto max-w-5xl px-5 md:px-10 py-10 md:py-20">
        <div className="py-5 md:py-10">
          <h2 className="text-lg md:text-xl font-sans font-medium pb-3 mb-7 border-b border-cyan-400 text-center">
            フォームでお問い合わせ
          </h2>
          <Form />
        </div>
        <div className="py-5 md:py-10">
          <h2 className="text-lg md:text-xl font-sans font-medium pb-3 mb-7 border-b border-cyan-400 text-center">
            メールでお問い合わせ
          </h2>
          <div className="text-lg text-center">
            <a href="mailto:info@mizuno-ch.com">info@mizuno-ch.com</a>
          </div>
        </div>
        <div className="py-5 md:py-10">
          <h2 className="text-lg md:text-xl font-sans font-medium pb-3 mb-7 border-b border-cyan-400 text-center">
            お電話でお問い合わせ
          </h2>
          <div className="flex flex-col gap-3">
            {companies.map((c, index) => (
              <div
                className="flex flex-col md:flex-row items-center justify-center"
                key={index}
              >
                <div className="w-full md:w-2/3 text-center md:text-left text-sm md:text-base">
                  {c.name}
                </div>
                <div className="w-full md:w-1/3 text-center md:text-left">
                  {c.tel}
                </div>
              </div>
            ))}
          </div>
          <p className="text-lg text-center mb-5"></p>
          <p className="text-sm"></p>
        </div>
      </div>
    </Layout>
  );
};

export default ContactPage;
